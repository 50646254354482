@import "../../../styles/index.scss";

.container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.reviewsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
}

.reviewCard {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    h3 {
        max-width: 300px;
    }
}
