@import "../../../../../styles/index";

.freelanceContainer {
    padding: 10px;
    box-shadow: 0 1px 2px #bbb;
    border-radius: 5px;
    position: relative;
    &:hover {
        box-shadow: 0 2px 8px #bbb;
        cursor: pointer;
    }
    &:hover:after {
        color: white;
        border-radius: 5px;
        padding: 6px;
        box-shadow: 0 2px 8px #bbb;
        top: 25%;
        left: 25%;
        cursor: pointer;
        background: #b3a7fb;
        position: absolute;
        content: "Proposer la mission à ce freelance";
    }
}

.searchContainer {
    display: flex;
    gap: 2rem;
    .filterContainer {
        width: max-content;
    }
}
@media only screen and (max-width: $mobileWidth) {
    .searchContainer {
        flex-wrap: wrap;
    }
}
