@import "../../../styles/index";

.container {
    margin: 40px;
    &.mobile {
        margin: 20px 10px;
    }
}

.titleContainer {
    margin-top: 36px;
}

.componentContainer {
    margin-top: 25px;
    form {
        max-width: 600px !important;
    }
}

.helpButton {
    margin-left: 5px;
}

.illustration {
    z-index: -1;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 65vh !important;
    &.mobile {
        height: auto!important;
        max-width: 100vw;
    }
}
