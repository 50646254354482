@import "../../styles/index.scss";

.root {
    &.mobile {
        height: 100%;
        width: 100%;
    }
    &:not(.mobile) {
        min-height: 100%;
        display: flex;
        flex: 1;
    }

}

.sideBarContainer {
    &.mobile {
        height: 100%;
        width: 100%;
    }
    &:not(.mobile) {
        width: $sideBarWidth;
        min-width: $sideBarWidth;
        overflow-y: auto;
    }
}

.burgerContainer {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 5px 0 0;
    z-index: $zCloud;
}

.contentContainer {
    overflow-x: auto;
    &.mobile {
        min-height: 100%;
        width: 100%;
    }
    &:not(.mobile) {
        flex: 1;
    }
}
