@import '../../styles/index';

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: $sz-small;
}

.icon {
	height: $sz-large;
}