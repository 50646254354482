@import "../../styles/index";

.wrapper {
	width: 125px;
	&.fullWidth {
		width: 100%;
	}
	height: 7px;
	border: solid 1px $primary-lighter;
	border-radius: $b-rad-large;
}

.bar {
	background-color: $primary-lighter;
	height: 100%;
	&.zero {
		width: 0%;
	}
	&.one {
		width: 10%;
	}
	&.two,
	&.type0 {
		width: 20%;
	}
	&.three {
		width: 30%;
	}
	&.four {
		width: 40%;
	}
	&.five,
	&.type1 {
		width: 50%;
	}
	&.six {
		width: 60%;
	}
	&.seven,
	&.type2 {
		width: 70%;
	}
	&.eight {
		width: 80%;
	}
	&.nine,
	&.type3 {
		width: 90%;
	}
	&.ten,
	&.type4 {
		width: 100%;
		background-color: $success-light;
	}
}
