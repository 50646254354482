@import "../../../styles/index.scss";

.list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 700px;
}

.displayReadContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: fit-content;
}
