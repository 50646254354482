@import '../../../styles/index';

.chipContainer{ 
    text-align: center;
    margin: 10px 0;
}

.buttonContainer{
    gap: 10px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}