@import "../../styles/index";

.container {
    font-family: "DM Serif Display", serif;
    font-size: $sz-huge;
    color: $white;
    flex: 6;
    background: $primary-grad;
    line-height: 1.2;
    & > div {
        text-align: left;
        max-width: 343px;
    }
}

.styleep {
    background: $white;
    color: $styleep-dark-blue;
    padding: 0 4px;
}
