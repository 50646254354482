@import "../../../styles/index";

$flashy_1: #ffadad;
$flashy_2: #b7ffad;
$flashy_3: #adfff8;
$flashy_4: #adb2ff;
$flashy_5: #e6adff;
$flashy_6: #ffadec;
$flashy_7: #fdddf8;
$flashys: ($flashy_1, $flashy_2, $flashy_3, $flashy_4, $flashy_5, $flashy_6, $flashy_7);
$creditCardWith: 250;

.cardPaymentContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  .subtitle {
    font-size: 12px;
    font-weight: 500;
    color: $primary;
  }
  > div {
    flex: 1;
  }
  .paymentCheckoutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .creditCardContainer {
    min-width: 400px;
    min-height: 400px;
    position: relative;
    .creditCard {
      position: absolute;
      background-color: $flashy_1;
      padding: 15px;
      color: black;
      border-radius: 5px;
      max-width: 250px;
      box-shadow: 0 2px 8px #bbb;
      cursor: pointer;

      &:hover,
      &.selected {
        box-shadow: 0 20px 80px #bbb;
      }
    }
    @for $i from 1 to length($flashys) {
      $top: 40 * $i;
      $left: 10 * $i;
      .creditCard:nth-child(#{$i}) {
        left: #{$left}px;
        top: #{$top}px;
        background: nth($flashys, $i);
        &:hover,
        &.selected {
          left: #{$left + 90}px;
        }
      }
      .type {
        margin-left: 15px;
      }
      .flex {
        margin-top: 40px;
        text-align: right;
      }
    }
  }
  .cardContainer {
    width: 370px;
  }
  .sepaContainer {
    width: 370px;
  }
}
.cardPaymentContainer {
  .price {
    background-color: white;
    padding: 0 30px;
    color: $primary;
    border: dashed 1px $primary;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 15px;
  }
}
.vaMiddle {
  vertical-align: middle !important;
}
.errorPayment {
  color: #ff6363 !important;
}
.starterOffersContainer {
  .card:first-child {
    margin-right: 15px;
  }
  .card:last-child {
    background-color: #4e54c813;
  }
  .card {
    border: solid 2px #4e54c8;
    border-radius: 10px;
    padding: 30px 40px;
    > * {
      margin: 20px 0 0 0;
    }
    .ui.radio.checkbox label:before {
      width: 30px;
      height: 30px;
      top: -5px;
      left: -10px;
      border: 2px solid $primary;
    }
    .ui.radio.checkbox label:after {
      top: -6px;
      left: -11px;
      width: 32px;
      height: 33px;
      background-color: $primary;
    }
    .subtitle {
      font-size: 15px;
      font-weight: 400;
      &.unavailable {
        opacity: 0.3;
      }
    }
  }
}

.mandateAcceptanceContainer {
  width: 300px;
  text-align: center;
  margin: auto;
}

.mandateAcceptance {
  margin-top: 10px;
  font-size: smaller;
}
.centerLoaderSubscription {
  width: 100%;
}

.nameInput {
  margin-right: 3px;
}

.cardElement {
  margin-left: 8px;
}
