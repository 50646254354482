@import "../../styles/index.scss";

.small {
    height: 50px!important;
}

.medium {
    height: 100px!important;
}

.large {
    height: 150px!important;
}

.larger {
    height: 200px!important;
}
.big {
    height: 400px!important;
}


.rounded{
    border-radius: 50%;
}

.contain{
    object-fit: contain;
}