@import "../../styles/index.scss";

.root {
  line-height: 1.4;
  font-family: Roboto;
  overflow-wrap: break-word;
  styleep {
    color: $styleep-dark-blue;
    font-family: "DM Serif Display", serif;
    content: "Styleep";
    letter-spacing: 1.1px;
  }
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
  text-underline-position: under;
}

.primaryColor {
  color: $primary;
}

.linkStyle {
  cursor: pointer;
  &:hover {
    font-weight: 800;
  }
}

.primaryBody {
  font: Roboto;
  font-weight: 400;
  font-size: $sz-base;
  line-height: 18px;
  color: $primary;
}

.faded {
  font: Roboto;
  font-weight: 400;
  font-size: $sz-base;
  line-height: 18px;
  color: $grey-3;
}

.body {
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: $sz-base;
  font-size: $sz-base;
}

.title {
  font-weight: 700;
  font-size: $sz-large;
}

.titleLittle {
  font-size: $sz-medium;
  font-weight: 700;
}

.text {
  font-size: $sz-base;
  letter-spacing: 0.02em;
  line-height: $sz-base;
  font-weight: 400;
}

.littleText {
  font-size: $sz-small;
  letter-spacing: 0.02em;
  line-height: $sz-base;
  font-weight: 400;
}

.subTitle1 {
  font-weight: 600;
  font-size: $sz-medium;
}

.subTitle2 {
  font-weight: 500;
  font-size: $sz-medium;
}

.subTitle3 {
  font-weight: 500;
  font-size: $sz-base;
}

.button {
  font-size: $sz-base;
  font-weight: 900;
}
