@import "../../styles/index";

.logoContainer {
	margin: 20px 0;
}

.container {
	min-height: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	position: fixed;
	width: $sideBarWidth;
	background: $primary-grad;
	&.mobile {
		width: 100%;
	}
}

.wrapper {
	flex: 1;
	padding-left: $sz-medium;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.linkContainer {
	max-height: calc(100vh - #{90 + 140 + 45}px); // logo(marginIncluded) + bottom + margin
	overflow-y: auto;
	&:not(:hover)::-webkit-scrollbar {
		display: none;
	}
}

.link {
	border-radius: $b-rad-large 0 0 $b-rad-large;
	transition: background-color 0.5s linear;
	display: flex;
	align-items: center;
	gap: $sz-medium;
	padding: $sz-base $sz-large;
	&.subStep {
		padding: $sz-base 0 0 $sz-large;
	}
	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.link:not(.subStep).active {
	background-color: $primary;
	transition: background-color 0.5s linear;
}

.link svg {
	width: $sz-large;
	height: $sz-large;
}

.subStep {
	display: flex;
	color: $white;
	&.done {
		color: $primary;
	}
}

.path {
	z-index: $zZero;
	top: -2px;
	position: absolute;
	margin-left: calc(#{$sz-large} + 8.5px);
	height: 33px;
	width: 6px;
	background-color: $white;
	transition: background-color 0.2s linear;

	&.active,
	&.done {
		background-color: $primary;
		transition: background-color 0.2s linear;
	}
}

.circleContainer {
	position: relative;
	display: inherit;
	z-index: $zMinimum;
	display: flex;
}

.circle {
	display: inline-block;
	z-index: $zMinimum;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	background-color: $white;
	transition: background-color 0.2s linear;

	&.active,
	&.done {
		background-color: $primary;
		transition: background-color 0.2s linear;
	}
}

.littleCircle {
	position: absolute;
	display: flex;
	z-index: $zMax;
	left: 5px;
	bottom: 5px;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	background-color: $white;
	transition: background-color 0.2s linear;
}

.label {
	color: $text-light;
}
