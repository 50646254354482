@import '../../../../styles/index';

.infosContainer{
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
}

.contractContainer{
    flex: 1;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}