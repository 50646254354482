@import "../../styles/index";

.container {
	border-radius: $b-rad-large !important;
	.header {
		border-bottom: none !important;
	}
}

.header {
	width: 100% !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	border-radius: 15px 15px 0px 0px !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.33) !important;
	.closeIcon {
		position: absolute;
		right: 0;
		top: 0;
	}
}

@media only screen and (min-width: $computerWidth) {
	.container {
		.header {
			padding: $contentMargin $contentMargin 0 $contentMargin !important;
		}
	}
}

@media only screen and (max-width: $mobileWidth) {
	.container {
		.header {
			padding: $contentMarginMobileV $contentMarginMobileH 0 $contentMarginMobileH !important;
		}
	}
}
