@import '../../styles/index';

.buttonWrapper {
	margin-bottom: $sz-base;
}

.rbc-selected-cell {
	background-color: $primary-bg !important;
}

.scheduler-add {
	background-color: $primary-bg !important;
}

.scheduler-remove {
	background-color: $warning-light !important;
}

.scheduler-remove-selected {
	background-color: $warning !important;
}

.default-day-cell {
	background-color: white;
}

.schedule-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $sz-base 0rem;
	margin-right: $sz-smallest;
}

.list-wrapper > .item:nth-child(2) {
	border-top: none !important;
}

.list-header {
	font-weight: 700 !important;
	font-size: $sz-medium !important;
}

.list-wrapper {
	padding: 1rem;
	max-height: 30vh;
	overflow-y: scroll;
}

.rbc-off-range-bg {
	pointer-events: none;
}
