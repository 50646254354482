.blockForTest {
    width: 200px;
    height: 200px;
    background-color: rgb(6, 0, 128);
}

.relative {
    position: relative;
}

.centeredContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.verticalCenteredContainer {
    display: flex;
    align-items: center;
}
.spaceBetween {
    justify-content: space-between;
}

.horizontalCenteredContainer {
    display: flex;
    justify-content: center;
}

.inlineBlock {
    display: inline-block;
}

.fullHeight {
    height: 100%;
}

.fullWidth {
    width: 100%;
}

.fullZindex {
    z-index: 10;
}

.flex {
    display: flex;
}

.flexWrap {
    flex-wrap: wrap;
    gap: 20px;
	&.mobile{
		gap: 10px;
	}
}

.gap1 {
    gap: 1rem;
}

.gap05 {
    gap: 0.5rem;
}

.width30 {
	width: 30%;
}

.absoluteImportant {
    position: absolute !important;
}

.hidden {
    display: none;
}

.hFit {
    height: fit-content;
}

.alignSelf {
    align-self: center;
}

.mt1 {
    margin-top: 10px !important;
}
.mt2 {
    margin-top: 20px !important;
}
.mb0 {
    margin-bottom: 0px !important;
}
.mb05 {
    margin-bottom: 5px !important;
}
.mb1 {
    margin-bottom: 10px !important;
}
.mb2 {
    margin-bottom: 20px !important;
}
.mb3 {
    margin-bottom: 3rem;
}
.ml1 {
    margin-left: 10px !important;
}
.ml2 {
    margin-left: 20px !important;
}
.mr1 {
    margin-right: 10px !important;
}
.mr2 {
    margin-right: 20px !important;
}

.noMargin {
    margin: 0;
}
.fitContent {
    max-width: fit-content !important;
}
.taCenter {
    text-align: center;
}

.whiteColor {
    color: white !important;
}

.formsChildLimited {
    form {
        max-width: 600px !important;
        margin: auto;
    }
}

.padding1 {
    padding: 1rem;
}

.cardShadow {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    &:hover,
    &.active {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
}

.pointer {
    cursor: pointer;
}

.columnDirection {
    flex-direction: column !important;
}

.fitContent {
    width: fit-content;
}
.ASflexEnd {
    align-self: flex-end;
}

.fixedBottom {
	position: fixed;
    bottom: 10px;
	z-index: 4;
}