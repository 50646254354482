.root {
	background-color: #fff;
	border: 2px solid #4285f4;
	color: #4285f4;
	display: flex;
	padding: 0;
	font-size: 14px;
	font-weight: 900;
	height: 44px;
	width: 100%;
	max-width: 100%;
	user-select: none;
	outline: none;
	cursor: pointer;
	border-radius: 4px;
	align-items: center;
	position: relative;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	min-width: 250px;
}

.root:hover {
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.icon {
	height: 18px;
	width: 18px;
	margin-right: 12px;
}