@import '../../styles/index';

.root{
    margin: 1rem 0;
    background-color: $primary;
    color: white;
    border-radius: 10px;
    text-align: center;
    width: 200px;
    padding: 5px;
}