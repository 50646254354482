@import "../../../../styles/index.scss";

.studioName {
    margin: 0;
    align-self: center;
}

.item {
    background-color: $grey-2;
    padding: 10px 25px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .middleItem {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}
