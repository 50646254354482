@import '../../styles/index';

.root {
	position: relative;
	background-color: $grey-2;
	border-radius: $b-rad;
	padding: $sz-medium;
	font-family: Roboto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	display: inline-block;
}

.icon {
	position: absolute;
	top: 0px;
	left: 0px;
	transform: translate(-50%, -50%);
}

.button  {
	background: $grey-5 !important;
	color: white !important;

	&:hover {
		background-color: $black !important;
	}
}


@media only screen and (max-width: $mobileWidth) {
	.root {
		margin: 0px 0px $contentMarginMobileH $contentMarginMobileH;
		flex-direction: column;
		gap: $sz-base;
	}
}