@import "../../_variable";

.inputUpload {
  display: "none";
}
.iconButton {
  padding: 0;
}
.popover {
  padding: $themeSpace + 0px $themeSpace * 2px;
}
.icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: $themeSpace + 0px;
}
.progress {
  margin-right: $themeSpace * 2px;
}
