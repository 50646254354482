@import "../../styles/index";

.container {
	display: flex;
	flex-direction: column;
	width: 325px;
	height: 325px;
	background: $white;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
	&:hover{
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	}
	border-radius: $b-rad-large;
}

.topContainer {
	position: relative;
	flex: 1;
	display: flex;
}

.bottomContainer {
	flex: 1;
	display: flex;
	background: rgb(255,255,255);
	background: linear-gradient(0deg, white 85%, #f9f9f9 100%);
}

.isAvailableContainer {
	display: flex;
	align-items: center;
	gap: 5px;
}

.img {
	flex: 1;
	background-size: cover !important;
}

.freelanceInfosContainer {
	justify-content: center;
	align-items: center;
	display: flex;
	flex: 1;
	margin-bottom: 5px;
}

.ratingLine {
	display: flex;
	margin-top: 1rem;
}

.ratingsCount {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: $sz-small;
	color: #5e5e5e;
	padding-left: $sz-smallest;
}

.name {
	font-size: $sz-large !important;
}

.circleWrapper {
	position: absolute;
	top: 5px;
	right: 5px;
	display: block;
	height: 9px;
	width: 9px;
}

.circle {
	height: 9px;
	width: 9px;
	display: block;
	border-radius: 100%;
	background-color: $grey-2;
	&.success {
		background-color: $success;
	}
}

.emptyCircle {
	opacity: 0%;
}

.detailsWrapper {
	display: flex;
	flex-direction: column;
}

.labelContainer {
	display: grid;
}

.label {
	display: inline-block;
	color: white;
	padding: 0.1rem 0.75rem;
	background-color: $primary;
	border-radius: $b-rad-smaller;
	font-weight: 500;
	&.tarif {
		background-color: $primary;
	}

	&.tarifTotal {
		background-color: $primary-lighter;
		margin-top: $sz-smallest;
	}
}

.bottomWrapper {
	margin-left: $sz-medium;
	margin-bottom: $sz-medium;
}

.daysWrapper {
	position: relative;
	.fullDays {
		position: absolute;
		flex-wrap: wrap;
		gap: 3px;
		top: -14px;
		display: none;
		min-width: 250px;
	}
	&:hover {
		.fullDays {
			display: flex;
		}
		.bullets {
			display: none;
		}
	}
}

.dayItem {
	align-self: start;
	flex-grow: 0;
	font-size: $sz-small;
	color: $grey-5;
	padding: $sz-smallestt $sz-smallest;
	border-radius: $b-rad;
	background-color: $grey-2;

	&:not(:first-child) {
		margin-left: 2px;
	}
}

.badgesContainer{
	overflow-x: auto;
	&::-webkit-scrollbar{
		display: none;

	}
}

.bottomContainerContent{
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}
