@import "../../styles/index";

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.appLogo {
	animation: App-logo-spin infinite 3s ease-in-out;
	height: 40vmin;
	pointer-events: none;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
