@import "../../styles/index";

.root {
	display: flex;
	height: 34px;
}

.starWrapper {
	width: 32px;
	height: 34px;
	position: relative;
	display: inline-block;
	&.little {
		height: 17px;
		width: 16px;
	}
}

.icon {
	position: absolute;
	height: 34px;
	width: 32px;
	&.little{
		height: 17px;
		width: 16px!important;
	}
}

.clippedBox {
	position: absolute;
	top: 0px;
	height: 34px;
	width: 32px;
	background-color: #d5c4fa;
	&.little{
		height: 17px;
		width: 16px!important;
	}
}
