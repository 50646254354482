@import "../../styles/variables/colors.scss";
@import "../../styles/variables/sizes.scss";

.menuContainer {
	border: 1px solid #adadad;
	display: flex;
	border-radius: 5px;
	width: fit-content;
	.itemContainer {
		cursor: pointer;
		padding: 8px 15px;
		&.defaultDayStatus {
			color: #583d72;
			background-color: $primary-lightest;
		}
		&:not(:last-child) {
			border-right: 1px solid #adadad;
		}

		&:hover,
		&:active {
			&.notHere {
				color: white;
				background-color: $danger-darky;
			}
			color: #583d72;
			background-color: $primary-lightest;
		}

		&.active {
			&.notHere {
				color: white;
				background-color: $danger-darky;
			}
			color: #583d72;
			background-color: $primary-lighter;
		}
	}
}
@media only screen and (max-width: $mobileWidth) {
	.menuContainer {
		flex-direction: column;
		.itemContainer {
			&:not(:last-child) {
				border-right: none;
			}
		}
	}
}
