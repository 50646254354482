@import "../../../styles/index.scss";

.root {
    border-radius: 5px;
    padding: 1rem 2rem;
    &.read {
        background-color: $grey-2;
    }
    .user {
        color: $primary-light;
    }
    .leenk {
        color: $info;
    }
    .mission {
        color: $success-light;
    }
    .freelance {
        color: $danger-lighter;
    }
    .studio {
        color: $secondary-light;
    }
}

.removeIconContainer {
    position: absolute!important;
    top: -20px;
    right: -20px;
}
