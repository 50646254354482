.rbc-header {
	padding-bottom: 16px;
}

.rbc-day-slot {
	border: none !important;
}

.rbc-off-range-bg {
	background-color: #e8e8e8 !important;
}

.rbc-today {
	background-color: #f7f5ff !important;
}

.externalScheduler .rbc-slot-selection {
	display: none !important;
}