@import '../../../styles/index';

.form{
    margin: auto;
    max-width: 500px!important;
}

.formField{
    margin-bottom: 1rem;
}

@media only screen and (max-width: $mobileWidth) {
    .form {
        max-width: 80%!important;
    }
}
