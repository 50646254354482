// Sizes generated: https://type-scale.com/
// Based on ratio 1.3333 Perfect Fourth

$sz-extra-huge: 4.209rem;
$sz-huge: 3.157rem;
$sz-extra-large: 2.369rem;
$sz-large: 1.777rem;
$sz-medium: 1.333rem;
$sz-base: 1rem;
$sz-small: 0.75rem;
$sz-smaller: 0.563rem;
$sz-smallest: 0.422rem;
$sz-smallestt: 0.22rem;

$b-rad-tiny: 2px;
$b-rad-smaller: 3px;
$b-rad-small: 4px;
$b-rad: 5px;
$b-rad-large: 10px;
$b-rad-huge: 50px;

$mobile-bp: 779px;

// LogGrid
$sideBarWidth: 240px;

//Media
$mobileWidth: 779px;
$computerWidth: 779px;
$contentMargin: 1.6rem;
$contentMarginMobileV: 1.15rem;
$contentMarginMobileH: 0.5525rem;