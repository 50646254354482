@import "../../../../styles/index.scss";

.root {
    display: flex;
}

.subtitle {
    flex-wrap: wrap;
    gap: 0.5rem;
    > * {
        align-self: center;
    }
}
