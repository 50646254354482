@import "../../styles/index";

.root {
    color: $primary;
    .inline.field > :first-child {
        margin: 0;
    }
}

.unavailable {
    filter: opacity(0.5);
    &:after {
        content: " ";
        display: block;
        background: rgba(1, 1, 1, 0.2);
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 10px;
        cursor: not-allowed;
    }
}

.limitContainer {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    animation: appears forwards 1s linear;
    .upgradeButtonContainer {
        display: inline-block;
        position: relative;
        button {
            width: 365px;
        }
        svg {
            position: absolute;
            right: -5%;
            top: 14%;
        }
    }
    .limit {
        text-align: center !important;
        color: $white;
        background-color: $primary-light;
        border-radius: 15px;
        padding: 15px;
    }
    .overlayIcon {
        position: relative;
    }
}
.formError {
    li:before {
        color: $danger !important;
    }
}

.messageLinkeable {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    &.withMessage {
        margin-top: -0.7em;
    }
}

.sendButton {
    position: absolute !important;
    z-index: $zMax;
    background: $primary;
}

.questionFormContainer {
    cursor: help;
    display: flex;
    align-items: center;
    .info {
        cursor: help;
        min-width: 15vw;
        margin-left: 35px;
        position: absolute;
        z-index: $zCloud;
        background: #e5e5f6;
        padding: 8px;
        border-radius: 3px;
        font-weight: 500;
        color: $black;
        font-size: small;
        cursor: help;
        &.top {
            bottom: 140%;
            left: -250%;
            &.maxWidth {
                max-width: 165px;
            }
        }
    }
    &:hover .info {
        display: block;
        cursor: help;
    }

    .icon {
        cursor: help;
        margin: 0 5px;
        color: #4e54c8;
        &:hover {
            opacity: 0.8;
        }
    }
}

.conformLabel {
    display: inline-block;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: baseline;
    font-size: 0.92857143em;
    font-weight: 700;
    color: $primary !important;
    text-transform: none;
    &.error {
        color: $danger !important;
    }
}

.field.required {
    label:after {
        display: inline-block;
        vertical-align: top;
        margin: -0.2em 0 0 0.2em;
        content: "*";
        color: $danger;
    }
}

.myInput {
    &.error {
        border: 1px solid $danger !important;
        transition: color 0.1s ease, border-color 0.1s ease;
        &:focus {
            border: 1px solid $danger-light !important;
            transition: color 0.1s ease, border-color 0.1s ease;
        }
    }
}

.myInputIsChild {
    &.error {
        input {
            border: 1px solid $danger !important;
            transition: color 0.1s ease, border-color 0.1s ease;
            &:focus {
                border: 1px solid $danger-light !important;
                transition: color 0.1s ease, border-color 0.1s ease;
            }
        }
    }
}

.descriptionInput {
    max-width: 550px;
}

.legendInput {
    font-size: smaller;
    margin-left: 5px;
    text-decoration: underline;
    text-underline-position: under;
    cursor: default;
    color: $primary;
}

.field .textContainer {
    width: 200px;
}

.field .keywordsContainer {
    align-items: center;
}

.keywordsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.keywordsContainer .label {
    margin-right: 10px;
    color: #ffffff;
    cursor: pointer;
    color: #565656;
    background-color: rgba(78, 84, 200, 0.1);
    -webkit-transition: background-color 1s;
    transition: background-color 1s;
}

.keywordsContainer .label.focus {
    color: #ffffff;
    background-color: #cb8cff;
}

.keywordsContainer .label:hover {
    color: #ffffff;
    background-color: #cb8cff;
}

.keywordsContainer.materials .label.focus:first-child {
    background-color: #ffaaaa;
}

.keywordsContainer.materials .label:first-child:hover {
    background-color: #ffaaaa;
}

.keywordsContainer.materials .label.focus:nth-child(2) {
    background-color: #fdf3be;
    color: #565656;
}

.keywordsContainer.materials .label:nth-child(2):hover {
    background-color: #fdf3be;
    color: #565656;
}

.keywordsContainer.materials .label.focus:nth-child(3) {
    background-color: thistle;
}

.keywordsContainer.materials .label:nth-child(3):hover {
    background-color: thistle;
}

.displayPasswordHeader {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: right;
    h5 {
        &.green {
            color: $success;
        }
        &.red {
            color: $danger-dark;
        }
    }
}

.previewImage {
    max-height: 50px;
}

.checkbox {
    label {
        color: $primary !important;
        &:after {
            color: $primary !important;
        }
        *:not(:hover) {
            color: $primary !important;
        }
    }
    &.error {
        label {
            color: $danger !important;
            &:after {
                color: $danger !important;
            }
            *:not(:hover) {
                color: $danger !important;
            }
        }
    }
}

.invalidEmailText {
    color: $danger;
}
