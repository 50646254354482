@import "../../styles/index";

.root {
    margin: 20px auto!important;
    &.noChildren {
        height: 0.5px !important;
        background: rgba(0, 0, 0, 0.85);
        width: 90%;
    }
}
