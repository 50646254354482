@import "../../../styles/index.scss";

$space: 2rem;
.root {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: inline-block;
    margin-top: 1rem;
    height: fit-content;
}

.partContainer {
    padding: $space;
}

.elementsContainer {
    display: flex;
    gap: 4rem;
}
.copyText {
    color: $grey-4;

    &:hover {
        cursor: pointer;
        color: $grey-5;
    }
}

.mb {
    margin-bottom: $space;
}

.elementContainer {
    margin-bottom: $space;
}

.sectionDivider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.27);
    width: 100%;
}
