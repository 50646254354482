@import "../../styles/index";

.container {
	padding: $sz-base $sz-large;
	display: flex;
	gap: $sz-medium;
	flex-gap: $sz-medium;
	align-items: center;
}

.firstName {
	font-size: $sz-medium;
	color: $text-dark;
	font-weight: 700;
	padding: 0;
	margin: 0;
}

.profession {
	color: $text-light;
	opacity: 0.7;
}

.emptyAvatar {
	border-radius: 100%;
	background-color: $danger-lighter;
	height: $sz-extra-large;
	width: $sz-extra-large;
}

.avatar {
	display: block;
	img {
		height: $sz-extra-large;
		width: $sz-extra-large;
		border-radius: 100%;
	}
}
