@import '../../styles/index.scss';

.root{
    margin: $contentMargin;
	position: relative;
}

@media only screen and (max-width: $mobileWidth) {
    .root{
        margin: $contentMarginMobileV $contentMarginMobileH;
    }
}

