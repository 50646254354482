@import '../../../../styles/index';

.root {
	width: max-content;
	height: 80vh;	
	background: $grey-1;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	border-radius: $b-rad-large;
	padding: $contentMargin;
}

.componentHeader {
	font-size: $sz-medium;
	color: $black;
	font-weight: 700;
}

.sectionHeader {
	font-size: $sz-base !important;
	color: $black !important;
	font-weight: 400 !important;
	display: block;
}


.header {
	display: flex;
	align-items: center;
	gap: $sz-small;
}

.checkBox {
	display: flex;
}

@media only screen and (max-width: $mobileWidth) {
	.root {
		padding: $contentMarginMobileH $contentMarginMobileV;
	}
}
