@import '../../styles/index';

.wrapperList {
	padding: 1rem;
	max-height: 30vh;
	overflow-y: scroll;
	> .item:nth-child(2) {
		border-top: none !important;
	}
}

.scheduleItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $sz-base 0rem;
	margin-right: $sz-smallest;
}

.dayStatus {
	border-radius: 0.5rem;
	padding: 0.5rem;
	background: $primary-lightest;
	color: $primary-light;
}
