@import "../styles/index.scss";

.shadow {
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.12), 0 1px 4px 0 rgba(34, 36, 38, 0.15);
    border-radius: 15px !important;
}

.hovered {
    background-color: #4e54c859 !important;
    &:hover {
        background-color: #4e54c859 !important;
    }
}

.readAndApproved {
    font-family: Satisfy!important;
    display: inline-block;
}
