@import '../../../../styles/index';

.label {
	color: $primary !important;
	padding-bottom: $sz-small;
}

.label::after {
	color: $text-required !important;
}

.input {
	width: 100% !important;
	padding: 0px !important;
	border: none !important;
}

.input input {
	border: 1px solid #000000 !important;
	border-radius: 4px !important;
}

.input [class^="ant-picker-input"] {
	border: none !important;
}

.input [role="listbox"] {
	border: 1px solid #000000 !important;
	border-radius: 4px !important;
	padding: $sz-small !important;
}

.input [class="divider default text"] {
	color: $text-place-holder !important;
	margin: 0px !important;
}

.input [class="text"] {
	margin: 0px !important;
}

.input::placeholder {
	color: $text-place-holder !important;
}

.button button {
	margin-top: $sz-small !important;
	background-color: $primary !important;
}