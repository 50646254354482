@import "../../styles/index";

.container {
    display: flex;
    height: 100%;
    width: 100%;
    background: $primary-grad;
}

.authFormHeader{
    display: flex;
    align-items: center;
}

.leftSideContainer {
    background: $white;
    flex: 4;
    display: flex;
    flex-direction: column;
    z-index: $zMax;
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar{
        display: none;
    }
}

.switchSignContainer {
    margin-bottom: 1.5em;
    text-align: center;
    & > div {
        display: inline-block;
        // min-width: 250px;
    }
}

.imageContainer {
    flex: 2;
    min-height: 125px;
}

.actionContainer {
    flex: 8;
}

.confirmAccountText {
    margin-right: 1em;
}

.formContainer {
    margin: 0 20px;
}

.divider {
    margin: 10px 0;
}

.language {
    z-index: $zCloud;
    position: absolute;
    top: 8px;
    right: 8px;
}
