@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "~semantic-ui-css/semantic.css";
.customScrollBar {
  &::-webkit-scrollbar-track {
    border: 0;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

