@import "../../styles/index.scss";

.root {
    position: relative;
    max-width: 100%;

    .MuiToolbar-gutters{
        display: none;
    }

    .MuiTableRow-root.MuiTableRow-hover:hover{
        background-color: $primary-lighter;
    }
    .MTableToolbar-root-5 {
        display: none;
    }
    > div {
        box-shadow: none;
    }
    .MTableToolbar-spacer-7 {
        display: none;
    }
    .MTableToolbar-searchField-10 {
        border: 1px solid #9e9e9e;
        border-radius: 8px;
    }
    .MuiInput-underline {
        &:before,
        &:after {
            display: none;
        }
    }
    td,
    th {
        border-bottom: none;
    }
    tbody {
        tr:nth-child(2n + 1) {
            background-color: $tr-table;
        }
    }
    thead {
        * {
            font-weight: 700 !important;
        }
    }
}
