@import "../../styles/index";

.calendar-loading {
	opacity: 0.5;
	position: relative;
}

.calendar-loader {
	left: 50% !important;
	top: 50% !important;
	position: absolute;
	z-index: 100;
	transform: translate(50%, 50%);
}

.calendar-container {
	font-family: Poppins;
	padding: 0px !important;
}

.rbc-month-view {
	border: 1px solid #e8e8e8 !important;
}
.rbc-month-row + .rbc-month-row {
	border-top: 1px solid #e8e8e8 !important;
}

.rbc-day-bg:not(.rbc-off-range-bg):nth-child(even) {
	background: $grey-1;
}

.rbc-day-bg:not(.rbc-off-range-bg):nth-child(odd) {
	background: white;
}

.rbc-day-bg + .rbc-day-bg {
	&:not(.scheduler-add) {
		border-left: none !important;
	}
	&.scheduler-add {
		border-left: 1px solid #e8e8e8 !important;
	}
}

.rbc-off-range {
	opacity: 0.3;
}

.rbc-off-range-bg,
.rbc-off-range-bg * {
	opacity: 0.3;
	background-color: $grey-3 !important;
}

.columnheader {
	margin: $sz-large !important;
}

.rbc-button-link {
	font-size: $sz-medium;
	font-weight: 900;
	margin: $sz-smaller 0 !important;
}

.rbc-btn-group {
	font-size: 1rem;
	white-space: normal !important;
}

.rbc-btn-group button {
	font-family: Roboto !important;
	box-shadow: none !important;
}

.rbc-btn-group button:not(.rbc-active) {
	color: $grey-3;
	background-color: $grey-1;
}

.rbc-btn-group .rbc-active {
	color: $primary !important;
	background-color: $primary-lightest !important;
}

.rbc-toolbar {
	flex-wrap: initial !important;
}

.rbc-toolbar-label {
	font-weight: 500;
	font-size: $sz-large;
	font-family: "Roboto" !important;
	text-transform: uppercase !important;
}
