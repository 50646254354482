@import "../../../../styles/index.scss";

.wrapperList {
	display: flex;
	flex-direction: column;
    padding: 1rem;
    max-height: 30vh;
    overflow-y: scroll;
    > .item:nth-child(2) {
        border-top: none !important;
    }
}

.scheduleItem {
	display: flex;
	justify-content: space-between;
	padding: $sz-base 0rem;
	margin-right: $sz-smallest;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: start;
}