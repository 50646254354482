@import "../../styles/index.scss";

.root {
    display: flex;
    gap: 3rem;
    margin: 1rem 0;
    flex-wrap: wrap;
}

.top {
    margin-bottom: auto;
}

.middle {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.bottom {
    margin-top: auto;
}


.imageContainer {
    display: inline-block;
}

.image {
    max-width: 300px;
}

.infosContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.levelBar {
    display: initial;
}

.addressContainer {
    max-width: 300px;
}
