@import "../../../styles/index.scss";

.descriptionContainer {
    min-width: 350px;
    max-width: 500px;
    max-height: 410px;
    overflow-y: auto;
}

@media only screen and (max-width: $mobileWidth) {
    .descriptionContainer {
        width: 300px;
        max-height: 300px;
    }
}

.actionContainer {
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
