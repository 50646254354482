@import "../../../styles/index.scss";

$finishedColor: #1f9254;
$finishedBg: #ebf9f1;

$inProgressColor: #cd6200; //WAITING
$inProgressBg: #fef2e5;

$WaitingColor: #0085ff; //action required
$WaitingBg: #e6f3ff; //action required

$cancelColor: #a30d11; //action required
$cancelBg: #fbe7e8; //action required

$openColor: #000000;
$openBg: #e8e8e8;

.root {
    border-radius: 22px;
    padding: 8px 12px;

    &.open {
        color: $openColor;
        background-color: $openBg;
    }
    &.inProgress {
        color: $inProgressColor;
        background-color: $inProgressBg;
    }
    &.waiting {
        color: $WaitingColor;
        background-color: $WaitingBg;
    }
    &.finished {
        color: $finishedColor;
        background-color: $finishedBg;
    }
    &.cancel {
        color: $cancelColor;
        background-color: $cancelBg;
    }
}
