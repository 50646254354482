@import "../../styles/index.scss";

$openColor: #0085ff;
$openBg: #e6f3ff;

$inProgressColor: #1f9254; //WAITING
$inProgressBg: #ebf9f1;

$WaitingColor: #cd6200; //action required
$WaitingBg: #fef2e5; //action required

$finishedColor: #a30d11;
$finishedBg: #fbe7e8;

$cancelledColor: #a30d11;
$cancelledBg: #fbe7e8;

.root {
    border-radius: 22px;
    padding: 8px 12px;
	width: fit-content;
}

.open {
	color: $openColor !important;
	background-color: $openBg !important;
}

.inProgress {
	color: $inProgressColor !important;
	background-color: $inProgressBg !important;
}

.waiting {
	color: $WaitingColor !important;
	background-color: $WaitingBg !important;
}

.finished {
	color: $finishedColor !important;
	background-color: $finishedBg !important;
}

.cancelled {
	color: $cancelledColor !important;
	background-color: $cancelledBg !important;
}
