@import "../../_variable";

.root {
    padding: $themeSpace * 4px;
    display: flex;
    flex-wrap: wrap;
}
.item {
    max-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $themeSpace * 1.75px;
}
.tooltipTitle {
    text-align: center;
    white-space: pre-line;
}
.label {
    color: rgba(0, 0, 0, 0.87);
    margin-top: $themeSpace * 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.icon {
    height: 50;
    width: auto;
    color: rgba(0, 0, 0, 0.55);
}
.img {
    width: 140px;
    height: 140px;
}
.imgNormal {
    width: 80px;
    height: 80px;
}
.imgLittle {
    width: 50px;
    height: 50px;
}
