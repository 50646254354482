.Toastify__progress-bar--info {
	background: linear-gradient(to right,  #583d72, #9282c9) !important;
}

.Toastify__progress-bar--success {
	background: linear-gradient(to right,  #583d72, #9282c9) !important;
}

.Toastify__progress-bar-theme--light {
	background: linear-gradient(to right,  #583d72, #9282c9) !important;
}

.Toastify__progress-bar--error {
	background: linear-gradient(to right,  #a30d11, #be5154) !important;
}