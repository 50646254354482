@import "../../../styles/index.scss";

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.numbersContainer {
    display: flex;
    justify-content: space-around;
}
