@import '../../../../styles/index.scss';

.studioName{
    margin: 0;
    align-self: center;
}

.item{
    &:not(:last-child){
        margin-bottom: 1rem;
    }
}